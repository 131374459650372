import { createApp } from 'vue';
import App from './App.vue';
import './css/style.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import router from './router/router.ts';
import state from './store';


createApp(App).use(router).use(state).mount('#app')
