<template>
    <div class="home-container">
      <div id="sidebar" class="sidebar">
        <div class="title-sidebar">
          <label for="">{{ nombrePersona }}</label>
          <h4>Administrador</h4>
        </div>
        <div class="sidebar-opt">
          <ul v-if="fonoDisplay == true">
          <li @click="goToMantenedorVentas();toggleSidebarClose()">Gestión de ventas</li>
          <li @click="goToMantenedorPersonas();toggleSidebarClose()">Gestión de usuarios</li>
        </ul>
        <ul v-else>
          <li @click="goToMantenedorVentas()">Gestión de ventas</li>
          <li @click="goToMantenedorPersonas()">Gestión de usuarios</li>
        </ul>
        </div>
  </div>
    <div class="navbar">
        <div class="toggle__sidebar"> 
          <img src="../assets/menu.svg" width="40" v-if="stateSideBar == true" @click="toggleSidebarClose()" />
          <img src="../assets/menu.svg" width="40" v-else @click="toggleSidebarOpen()" />
        </div>
        <div class="nav-buttons">
        <!-- Aquí puedes agregar el contenido del navbar -->
        <p @click="cerrarSesion()">Cerrar Sesión</p>
      </div>

      
      
    </div>
    <div class="content">
      <router-view ></router-view>
    </div>
  </div>
  </template>
<script>
import state from '@/store';

export default  {
  name: 'Home',
  props: {
    nombrePersona: {
      type: String,
      required: true
    },
  },
  data() {
    return { 
      stateSideBar: true,
    }
  },
  computed: {
    isLogged: () => state.isLogged,
    fonoDisplay: () => state.fonoDisplay,
  },
  mounted(){
    console.log(this.isLogged)
    console.log(this.nombrePersona)
    if(!this.nombrePersona){
      this.$router.push('/login');
    }
  },
  methods: {
    goToMantenedorPersonas(){
      this.$router.push('/personas');
    },
    goToMantenedorVentas(){
      this.$router.push('/ventas');
    },
    cerrarSesion(){
      state.isLogged = false;
      this.$router.push('/login');
    },
    toggleSidebarClose(){
      document.getElementById("sidebar").classList.add("sidebar-open");
      this.stateSideBar = false;
    },
    toggleSidebarOpen(){
      document.getElementById("sidebar").classList.remove("sidebar-open");
      this.stateSideBar = true;
    }
  }
}
</script>
<style scoped>

</style>
