<template>
    <div class="login-container">
        <div class="form-container">
        <!-- Contenido del formulario -->
            <img class="logo" src="../assets/Logouptraveldorado.png" width="200" alt="logoupgradetravel" srcset="" >
            <h3>INGRESO A LA PLATAFORMA</h3>
            <label>Introduce tu rut y contraseña para ingresar</label>
            <div class="form-login">
                    <div class="form-input gap">
                        <label class="form-label">Rut</label>
                        <input class="form-control" type="text" v-model="persona.rut" placeholder="Ej: 12.345.678-9" aria-label="default input example">
                    </div>
                    <div class="form-input gap">
                        <label class="form-label">Contraseña</label>
                        <input type="password" id="inputPassword5" v-model="persona.pass" class="form-control" placeholder="***********">
                    </div>
                    <div class="d-grid gap">
                    <button class="btn btn-warning" type="button" @click="login()">Ingresar</button>
                    </div>
            </div>
        </div>
        <div class="background" v-if="fonoDisplay == false"></div>
        
  </div>
</template>
<script>
import state from '@/store';
import dataPersonas from '../data/Personas.json';

export default  {
  name: 'Login',
  data() {

    return { 
        backgroundUrl: '../assets/login-background.jpg',
        persona: {
            rut: '',
            pass: '',
        },
        dataPersonas: dataPersonas,
    }
  },
  computed: {
    fonoDisplay: () => state.fonoDisplay,
  },
  methods: {
        login(){
            state.isLogged = true;
            const persona = this.dataPersonas.filter(p => p.rut == this.persona.rut)
            console.log(persona)
            this.$router.push({
                name: 'Home',
                params: {
                    nombrePersona : `${persona.length == 0 ? 'Invitado' : persona[0].nombre }`
                }
                });
            
        }
  }
}
</script>
<style scoped>
    label{
        color: #eee;
        opacity: 0.7;
    }
    .login-container {
        display: flex;
        width: 100vw;
        height: 100vh;
    }
    .background{
        flex: 1;
        background-image: url('../assets/login-background.jpg');
        background-size: cover;
        background-position: center;
    }
    .form-container{
        place-self: center;
        text-align: -webkit-center;
        color: #ffffff;
        flex: 3;
    }
    .form-login{
        width: 500px;
        text-align: start;
        background-color: #eee;
        border-radius: 5px;
        margin: 30px;
        padding: 30px;
        box-sizing: border-box;
    }

    .form-label{
        color: #333;
        font-size: 16px;
        margin-bottom: 8px;
    }
    .form-input{
        margin: 0;
    }

    .form-control{
        background: transparent;
        border: 1px solid #c6c6c6;
    }
    .form-control:focus{
        background: transparent;
    }
    .d-grid{
        margin: 0;
        
    }
    .btn{
        border: 1px solid #333;
        color: #333;
        background: transparent;
    }
    .btn:hover{
        border: #ffffff;
        color: #ffffff;
        background: #333;
    }
    .logo{
        margin-bottom: 40px;
    }
</style>
