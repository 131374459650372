<template>
    <div>
        <h1>Mantenedor venta</h1>
    </div>
</template>
<script>
export default  {
  name: 'MantenedorVenta',
  data() {
    return { 
        
    }
  },
  methods: {
  }
}
</script>
<style scoped>
   
</style>
