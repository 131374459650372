import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/components/Login.vue';
import Home from '@/components/Home.vue';
import MantenedorPersona from '@/components/Persona/MantenedorPersona.vue';
import MantenedorVenta from '@/components/Venta/MantenedorVenta.vue';

const routes = [
  { path: '/', redirect: '/login',  component: Login },
  { path: '/login', name: 'Login', component: Login },
  {path:  '/home', name: 'Home', props: true, component: Home, children: [
    {path:  '/personas', name: 'MantenedorPersona', component: MantenedorPersona},
    {path:  '/ventas', name: 'MantenedorVenta', component: MantenedorVenta}
  ]}
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
