<template>
    <div class="sub__container">
        
        <div class="initial__content">
            <div class="title__content">
                <h2>Gestión de usuarios</h2>
            </div>
            <div class="action__content">
                <button type="button" class="btn" data-bColor="casiNegro" data-size="btnAction" data-bs-toggle="modal" data-bs-target="#modalNuevoRegistroUserSelect" >Nuevo registro</button>
            </div>
        </div>
        <!-- TABLA MOVIL -->
        <table class="table" v-if="fonoDisplay == true">
      <thead>
        <tr>
          <th widht="100"></th>
          <th scope="col">Nombre</th>
          <th scope="col">Rol/Función</th>
          
        </tr>
      </thead>
      <tbody class="table-group-divider" >
        <tr v-for="persona in dataPersonas">
          <td><a data-bs-toggle="modal" data-bs-target="#modalEditarRegistroUserForm" @click="usuarioSelected = {...persona}">Ver más</a></td>
          <td>{{ persona.nombre }}</td>
          <td>{{ persona.rol }}</td>
        </tr>
      </tbody>
        </table>
        <!-- TABLA PC -->
        <table class="table" v-else>
      <thead>
        <tr>
          <th scope="col">No Rut</th>
          <th scope="col">Nombre Completo</th>
          <th scope="col">Rol/Función</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody class="table-group-divider" >
        <tr v-for="persona in dataPersonas">
          <td>{{ persona.rut }}</td>
          <td>{{ persona.nombre }}</td>
          <td>{{ persona.rol }}</td>
          <td>
            <button type="button" class="btn" data-bColor="blanco" data-size="celda" data-bs-toggle="modal" data-bs-target="#modalEditarRegistroUserForm" @click="usuarioSelected = {...persona}">Editar</button>
            <button type="button" class="btn" data-bColor="gris" data-size="celda">Eliminar</button>
          </td>
        </tr>
      </tbody>
        </table>


        <!-- MODALES -->

        
        <div class="modal fade" id="modalNuevoRegistroUserSelect" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" >
    <div class="modal-content" data-size="modalRegistroSelect">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Nuevo registro de usuario</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>Selecciona el tipo de usuario a registrar</p>
        <select class="form-select" v-model="newUsuario.rol" aria-label="Default select example">
        <option value="Administrador">Administrador</option>
        <option value="Ejecutivo">Vendedor</option>
        <option value="Otro">Otro</option>
        </select>
      </div>
      <div class="modal-footer">
        <button id="btnNextModal" class="btn" data-bColor="casiNegro" data-bs-target="#modalNuevoRegistroUserForm" data-bs-toggle="modal" :disabled="!newUsuario.rol" >Siguiente</button>
    </div>
    </div>
  </div>
</div>
<div class="modal fade" ref="modalNuevoRegistroUserForm" id="modalNuevoRegistroUserForm" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" data-size="modalRegistroForm">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">Nuevo registro de usuario</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form__content">
            <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">RUT</label>
        <input type="text" class="form-control"  v-model="newUsuario.rut" id="exampleFormControlInput1" placeholder="12.345.678-9">
        </div>
        <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Nombre Completo</label>
        <input type="text" class="form-control"  v-model="newUsuario.nombre" id="exampleFormControlInput1" placeholder="Ej: Andrés Lopez">
        </div>
        <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Contraseña</label>
        <input type="password" class="form-control"  v-model="newUsuario.pass" id="exampleFormControlInput1" placeholder="***********">
        </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn"  data-size="celda" data-bColor="blanco" data-bs-target="#modalNuevoRegistroUserSelect" data-bs-toggle="modal">Atrás</button>
        <button class="btn" data-size="celda" data-bColor="casiNegro" data-bs-target="#modalNuevoRegistroUserForm" :data-bs-dismiss="'modal'" @click="registrarUsuario('nuevo')" :disabled="!newUsuario.rut && !newUsuario.nombre && !newUsuario.pass">Registrar</button> 
    </div>
    </div>
  </div>
</div>
<div class="modal fade" ref="modalEditarRegistroUserForm" id="modalEditarRegistroUserForm" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" data-size="modalEditUserForm">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">Editar registro de usuario</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form__content">
            <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">RUT</label>
        <input type="text" class="form-control"  v-model="usuarioSelected.rut" id="exampleFormControlInput1" placeholder="12.345.678-9">
        </div>
        <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Nombre Completo</label>
        <input type="text" class="form-control"  v-model="usuarioSelected.nombre" id="exampleFormControlInput1" placeholder="Ej: Andrés Lopez">
        </div>
        <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Contraseña</label>
        <input type="password" class="form-control"  v-model="usuarioSelected.pass" id="exampleFormControlInput1" placeholder="***********">
        </div>
        <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Rol/Función</label>
        <select class="form-select" v-model="usuarioSelected.rol" aria-label="Default select example">
        <option value="Administrador">Administrador</option>
        <option value="Ejecutivo">Ejecutivo</option>
        <option value="Otro">Otro</option>
        </select>
        </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn"  data-size="celda" data-bColor="blanco" data-bs-target="#modalEditarRegistroUserForm" data-bs-toggle="modal">Cancelar</button>
        <button class="btn" data-size="celda" data-bColor="casiNegro" data-bs-target="#modalEditarRegistroUserForm" :data-bs-dismiss="'modal'" @click="registrarUsuario('edit')" >Guardar</button> 
    </div>
    </div>
  </div>
</div>
    </div>
</template>
<script>
import state from '@/store';
import dataPersonas from '../../data/Personas.json'
export default  {
  name: 'MantenedorPersona',
  data() {
    return { 
        dataPersonas: dataPersonas,
        newUsuario: {
            rut: '',
            nombre: '',
            pass: '',
            rol: ''
        },
        usuarioSelected: {}
    }
  },
  computed: {
    fonoDisplay: () => state.fonoDisplay,
  },
  methods: {
    validarCampos(){
        if(!this.newUsuario.rut){
                alert('Ingrese un rut válido.');
                return false
        }else if(!this.newUsuario.nombre){
                alert('Ingrese un nombre válido.');
                return false
        }else if(!this.newUsuario.pass){
                alert('Ingrese una contraseña válida.');
                return false
        }
        return true
    },

    registrarUsuario(type){
        const user = type == 'nuevo' ? this.newUsuario : this.usuarioSelected
        if(!user.rut){
                alert('Ingrese un rut válido.');
                return false
        }else if(!user.nombre){
                alert('Ingrese un nombre válido.');
                return false
        }else if(!user.pass){
                alert('Ingrese una contraseña válida.');
                return false
        }
        if(user == this.newUsuario) this.dataPersonas.push(this.newUsuario);
        
    }
  }
}
</script>
<style scoped>
   
</style>
