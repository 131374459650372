<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import state from './store';

export default {
  name: 'App',
  mounted(){
      console.log(window.screen.width);
      const screenWidth = window.screen.width;
      state.fonoDisplay = screenWidth <= 768 ? true : false;
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
